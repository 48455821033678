.inputContainer {
  @apply !relative;
}

.inputContainer input {
  transition: background-color 0.3s ease !important, color 0.3s ease !important;
}

.inputContainerFocused {
  & .label,
  & .rightIcon {
    @apply !text-stone-500;
  }
}

.label {
  @apply !transition-all !transform-gpu !absolute !left-12 !top-16 !paragraph-16 !text-display-600;
}

.labelActive {
  @apply !paragraph-12 !font-semibold !text-display-700;
  transform: translateY(-8px) !important;
}

.input {
  @apply !pb-[8px] !pt-[24px] !h-56 !text-display-900;

  &:hover {
    @apply !border-stone-500 !ring-0;
  }

  &:focus {
    @apply !border-stone-500 !ring-0;
    box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5) !important;
  }

  &:disabled {
    @apply !opacity-50 !border-gray-400;
    border: 1px solid var(--Display-400, #bcc8d6) !important;
    background: var(--Display-100, #eff4f8) !important;
  }
}

.hasError {
  & .input {
    @apply !border-red-500 hover:border-red-500 focus:border-red-500 focus:shadow-none;
  }

  & .labelActive,
  & .rightIcon {
    @apply !text-red-500;
  }
}

.inputContainerHasRightIcon {
  & .input {
    @apply !pr-48;
  }
}

.rightIcon {
  @apply !text-display-900 !fill-current !w-24 !h-24 !absolute !right-12 !top-16;
}
